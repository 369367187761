import React from "react";
import {
  ABOUT_VIR_SECTION_HEADING,
  ABOUT_VIR_SUB_SECTION_HEADING,
  ABOUT_VIR_SECTION_DESCRIPTION
} from "../../constants.js";
import SectionHeading from "../../components/ui/SectionHeading.js";

const vision = <>
  <p>To be India's leading electric cycle brand, celebrated for distinctive design, innovative features, and a pioneering spirit.</p>
</>

const mission = <>
  <p>To deliver an exceptional riding experience that inspires all riders to reach new heights in electric cycling.</p>
</>

const WHY_US_DATA = [
  {
    id: 1,
    title: "Our Vision",
    description: vision,
  },
  {
    id: 2,
    title: "Our Mission",
    description: mission,
  }
]

const AboutVir = () => {
  return (
    <section id="about-vir-section">
      <div className="container about-vir-container">
        <div className="about-vir-img d-none d-lg-block">
          {/* <figure className="img-container w-100">
            <img className="img-fluid w-100" src="/images/about-vir.jpg" alt="" />
          </figure> */}
        </div>
        <div className="about-vir-content d-flex flex-column align-items-around align-self-center">
          <div className="about-vir-content_top">
            <SectionHeading 
              heading={ABOUT_VIR_SECTION_HEADING} 
              headingClass={''} 
              subHeading={ABOUT_VIR_SUB_SECTION_HEADING} 
              subHeadingClass={''} />
            <div className="sub-content">
              {ABOUT_VIR_SECTION_DESCRIPTION}
            </div>
            <figure className="d-block d-lg-none img-container w-100">
              <img className="img-fluid w-100" src="/images/about-vir.jpg" alt="" />
            </figure>
          </div>
          <div className="about-vir-cards mt-5">
            {WHY_US_DATA.map(item => {
              return (
                <div key={item.id+'ws'} className="about-vir-card-content">
                  <h3 className="text-dark-gray">{item.title}</h3>
                  <p className="mt-3">{item.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutVir;