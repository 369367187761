// Section Heading
export const HERO_HEADING = "Vir Bike"
export const ABOUT_HEADING = "About"
export const FEATURE_SECTION_HEADING = "New"
export const PRODUCT_SECTION_HEADING = "Our Products"
export const WHY_SECTION_HEADING = "Why Us"
export const TESTIMONIAL_HEADING = "Happy Clients"
export const FAQ_SECTION_HEADING = "FAQ"
export const ABOUT_VIR_SECTION_HEADING = "About Us"
export const LEADERSHIP_SECTION_HEADING = "Leadership"
export const CONTACT_HEADING = "Contact"
export const ACHIEVEMENTS_SECTION_HEADING = "Col Bharat Pannu"
export const ACTIVITIES_SECTION_HEADING = "Rallies and Event"
export const CART_HEADING = "Your shopping"


// Section Sub Heading
export const HERO_SUB_HEADING = "Sustainability echoes across Every Single Mile"
export const ABOUT_SUB_HEADING = "VIR Bike"
export const FEATURE_SECTION_SUB_HEADING = "Adventure's spirit"
export const PRODUCT_SECTION_SUB_HEADING = "VIR Bike Models"
export const WHY_SECTION_SUB_HEADING = "Timeless designs thrill rides"
export const TESTIMONIAL_SUB_HEADING = "Our Testimonials"
export const FAQ_SECTION_SUB_HEADING = "Frequently Asked Questions"
export const ABOUT_VIR_SUB_SECTION_HEADING = "Adventure starts with the first pedal"
export const LEADERSHIP_SUB_SECTION_HEADING = "People Behind VIRBIKE"
export const CONTACT_SUB_HEADING = "Get in touch"
export const ACHIEVEMENTS_SUB_SECTION_HEADING = "Sponsoring Champions"
export const ACTIVITIES_SUB_SECTION_HEADING = "Adventure with VIR Community"
export const CART_SUB_HEADING = "Cart"



// Section Description
export const ABOUT_SECTION_DESCRIPTION = "Discover VIR Bike, inspired by the power of V=IR in Ohm's law, and designed to capture the spirit of our heroes. Our products combine style, performance, and innovation, offering unmatched value and reliability. Experience VIR, where design meets the aspiration to inspire riders of all ages."
export const FEATURE_SECTION_DESCRIPTION = "We grasp the thrill and wanderlust found in biking"
export const ABOUT_VIR_SECTION_DESCRIPTION = "Explore VIR, a brand inspired by the dynamic fusion of V=IR in Ohm's law, capturing the bold essence of our heroes. VIR BIKE leads the way in EV technology for unparalleled last-mile connectivity, presenting a compact adventure of courage and innovation in each model. Embark on an extraordinary journey with VIR into the core of bravery and pioneering innovation."
export const CONTACT_SECTION_DESCRIPTION = "Step into VIR Bike, where your cycling adventure unfolds! As enthusiasts of cycling, we grasp the exhilarating sense of freedom that accompanies each pedal."

// Misc
export const READ_MORE = "Read More"
export const BOOK_NOW = "Book Now"
export const ORDER_NOW = "Order Now"