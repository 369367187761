import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { APIHandler } from "../../utils/axiosInstance";

import ProductCarousel from "./ProductCarousel";
import ProductDetail from "./ProductDetail";
import Seo from "../../components/common/Seo";

const ProductDetails = () => {
  const { productId } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [product, setProduct] = useState([]);

  useEffect(() => {
    const fetchProductById = async () => {
      const res = await APIHandler(
        "GET",
        `/products/${productId}`,
        null,
        {
          "content-type": "application/json; charset=utf-8"
        },
      );

      if (res.status !== 200) {
        console.log("No data is present")
      } else {     
        setIsLoaded(true);
        setProduct(res.data);
      }
    }

    fetchProductById();
  }, [isLoaded, productId]);


  return (
    <>
      <Seo 
        title="VIR Bike V1 Electric Cycle - Advanced, Durable, and Stylish"
        description="The VIR Bike V1 electric cycle offers unmatched durability with an aluminum alloy frame, front suspension, and a 50km range. Ideal for men, women, and children, including military-grade options for armed forces."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      { isLoaded && product && (
        <section id="product-detail-section">
          <div className="container-fluid product-detail-container p-0">
            <div className="prod-details_left-col">
              <div className="prod-details-heading">
                {product.productName}
              </div>
              <div>
                <ProductCarousel images={product.productImages}/>
                <div className="details-and-image">
                  <div className="product-details">
                    <h2 className="product-details-heading mb-5">Details</h2>
                    {
                      product?.specs?.map(item => (
                        <>
                          <div 
                            className="product-details-info row"
                            key={item.name}>
                            <div className="col fw-bold">
                              <p>{item.name}</p>
                            </div>
                            <div className="col text-dark-gray">
                              <p>{item.value}</p>
                            </div>                      
                          </div>
                          <hr />
                        </>
                      ))
                    }  
                  </div>
                  <figure className="mt-0">
                    <img src="/images/height.jpg" className="img-fluid w-100" alt="VIR V1" />
                  </figure>
                </div>
                {
                  product && product.bannerPrimary &&
                  <figure>
                    <img src={product.bannerPrimary} className="img-fluid w-100" alt="VIR V1" />
                  </figure>
                }
                {
                  product && product.bannerSecondary &&
                  <figure>
                    <img src="/images/v1_banner.jpg" className="img-fluid w-100" alt="VIR V1" />
                  </figure>
                }
              </div>
              {/* <div className="prod-details_short-description">
                {
                  product?.specs?.map(item => (
                    <div 
                      className="short-description-info"
                      key={item.name}>
                      <p>{item.name}</p>
                      <h5>{item.value}</h5>
                    </div>
                  ))
                }            
              </div> */}
            </div>
            <div className="prod-details_right-col">
              <ProductDetail product={product}/>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ProductDetails;