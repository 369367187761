import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/ui/Button.js";
import SectionHeading from "../../components/ui/SectionHeading.js";

import {
  FEATURE_SECTION_HEADING,
  FEATURE_SECTION_SUB_HEADING,
  FEATURE_SECTION_DESCRIPTION,
  READ_MORE
} from "../../constants.js";

const FEATURE_DATA = [
  {
    id: 1,
    title: "Motor (BLDC)",
    content: "Throttle and Walk Assist Speed limited with",
    feature: "25 Kmph*"
  },
  {
    id: 2,
    title: "Charger (AC)",
    content: "Portable, Home charging socket",
    feature: "2.5 Hrs*"
  },
  {
    id: 3,
    title: "Display (LCD)",
    content: "Multifunctional with speed and range indicator",
    feature: "5 Level PAS"
  },
  {
    id: 4,
    title: "Battery (Detachable)",
    content: "Intube Battery",
    feature: "Upto 75 Km*"
  },
]

const FeatureSection = () => {
  const navigate = useNavigate();

  return (
    <section id="feature-section" className="section pb-0">
      <div className="container-fluid d-flex flex-column flex-lg-row justify-content-end">
        <div className="feature-section-content d-flex flex-column align-items-start justify-content-center">
          <div className="features-wrapper">
            <div className="d-flex flex-column justify-content-center gap-5">
              <div>
                <SectionHeading 
                  heading={''} 
                  headingClass={''} 
                  subHeading={FEATURE_SECTION_SUB_HEADING} 
                  subHeadingClass={''} />
                <div className="content d-none">
                  {FEATURE_SECTION_DESCRIPTION}
                </div>
              </div>
              <div className="features">
                {FEATURE_DATA.map(item => {
                  return (
                    <div className="features-item" key={item.id+'feature'}>
                      <div className="features-item_title">
                        <span>{item.title}</span>
                      </div>
                      <div className="features-item_content">
                        {item.content}
                      </div>
                      <div className="features-item_feature">
                        {item.feature}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="feature-container-links">
                <Button className={'feature-btn'} onClick={() => {navigate(`/product-listing`)}}>
                  SHOP NOW
                </Button>
              </div>
            </div>
            <div>
              <figure>
                <img src="/images/feature-sec.png" class="img-fluid w-100" alt="About VIR Bike" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeatureSection;