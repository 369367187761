

import AboutVir from "./AboutVir";
import DrivesUs from "./DrivesUs";
import Leadership from "./Leadership";
import Seo from '../../components/common/Seo';

const About = () => {
  return ( 
    <>
      <Seo 
        title="About VIR Bike - Innovating Premium Electric Cycles for All"
        description="Learn about VIR Bike's mission to provide high-quality electric cycles with advanced features like BLDC motors, durable alloy frames, type C connectors and unisex designs, catering to both corporates and armed forces for sustainability."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <AboutVir />
      <Leadership />
      <DrivesUs />
    </>
  )
}

export default About;