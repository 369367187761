import React from "react";
import {
  ACHIEVEMENTS_SECTION_HEADING,
  ACHIEVEMENTS_SUB_SECTION_HEADING
} from "../../constants.js";
import SectionHeading from "../../components/ui/SectionHeading.js";

const KeyAchievements = () => {
  return (
    <section id="key-achievements-section">
      <div className="container achievements d-flex justify-content-center">
        <div className="achievements-content d-flex flex-column flex-lg-row">
          <div>
            <SectionHeading 
              heading={ACHIEVEMENTS_SECTION_HEADING} 
              headingClass={'achievements-content-heading'} 
              subHeading={ACHIEVEMENTS_SUB_SECTION_HEADING} 
              subHeadingClass={'achievements-content-subHeading'} />
              <h4 className="mt-5">VIR Bikes Cheers Col Bharat Pannu on RAAM Conquest!</h4>
              <h4 className="mt-4">VIR Bikes is pumped to sponsor Col Bharat Pannu as he tackles the brutal 2024 Race Across America (RAAM)!</h4>

              <div className="achievements-content-text">
                <p>This endurance legend, known for crushing Guinness World Records, perfectly embodies the VIR spirit with his relentless drive and ultra-cycling achievements. From conquering iconic Indian routes like Leh-Manali and the Golden Quadrilateral to battling it out in international races like RAAM, Col Pannu inspires us all to push our limits.</p>
              </div>

              <div className="achievements-img-container mt-5">
                <figure>
                  <img className="achievements-img" src="/images/col.jpg" alt="" />
                </figure>
                <figcaption className="mt-3 text-center fs-4">
                  Col Bharat Pannu (In action during race) 2024 Race Across America (RAAM) <br />
                  <b>Winning the Armed Forces Cup at RAAM 2024</b>
                </figcaption>
              </div>
          </div>
          <div className="d-flex flex-column-reverse flex-lg-column">
            <div className="achievements-img-container">
              <figure>
                <img className="achievements-img" src="/images/pannu-sir.jpg" alt="" />
              </figure>
            </div>
            <div className="mt-5 mb-5">
              <h3>Why We Partner with Champions:</h3>
              <p>Sponsoring Col Pannu reflects our commitment to supporting exceptional athletes who live and breathe excellence and passion. We're cheering him on as he journeys further, leaving an undeniable mark on the world of cycling.</p>
              <p>Join the Celebration! Follow Col Pannu's incredible RAAM adventure and celebrate his achievements with the VIR community!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default KeyAchievements;