import React, { useState } from 'react';
import { toast } from "react-toastify";
import InputWithButton from './InputWithButton';
import { APIHandler } from '../../utils/axiosInstance';

const Newsletter = ({ heading }) => {
  const [formData, setFormData] = useState({
    email: ''
  });
  
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      const res = await APIHandler(
        "POST",
        "/subscription",
        JSON.stringify(formData),
        {
          "content-type": "application/json; charset=utf-8"
        },
      );

      if(res.data) {
        toast.success(`You've successfully signed up for VIR Community.`);
        setFormData({
          email: ''
        });
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <section id="newsletter">
      <div className="d-flex justify-content-center newsletter">
        <figure className="newsletter-img-container w-100">
          <img className="newsletter-img w-100" src="/images/newsletter.jpg" alt="" />
          <div className="newsletter-caption">
            <h2>{heading}</h2>
            <p className='mt-4'>Sign up for the VIR Community</p>
            <p><b>Let's Ride</b> We'll see you on the road.</p>
            <div className='sign-up-wrapper mt-4'>
              <InputWithButton
                placeholder="Sign me up!" 
                onButtonClick={handleClick} 
                name="email"
                value={formData.email}
                onChange={handleChange}
                />
            </div>
          </div>
        </figure>
      </div>
    </section>
  );
};

export default Newsletter;
