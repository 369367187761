import React, { useState } from 'react';

const ProductCarousel = ({ images = [] }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToPreviousImage = () => {
    setCurrentImageIndex(prevIndex => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex(prevIndex => 
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  if (images.length === 0) return <p>No images available</p>;

  return (
    <div className="product-carousel-container">
      <button className="carousel-arrow left-arrow" onClick={goToPreviousImage}>
        &lt;
      </button>
      <div className="main-image-container">
        <img src={images[currentImageIndex]} alt="Product" />
      </div>
      <button className="carousel-arrow right-arrow" onClick={goToNextImage}>
        &gt;
      </button>
    </div>
  );
};

export default ProductCarousel;