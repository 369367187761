import React from "react";
import Button from "../../components/ui/Button";
import SectionHeading from "../../components/ui/SectionHeading";

import {
  HERO_HEADING,
  HERO_SUB_HEADING
} from "../../constants.js"

const Hero = () => {
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container-fluid hero">
        <div className="hero-content d-flex flex-column">
          {/* <SectionHeading
            heading={HERO_HEADING} 
            headingClass={'hero-heading'} 
            subHeading={HERO_SUB_HEADING} 
            subHeadingClass={'hero-info'} />
          <div className="hero-links">
            <Button className={''} onClick={() => {}}>
              Our Bikes
            </Button>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default Hero;