import React from "react";
import {
  ACTIVITIES_SECTION_HEADING,
  ACTIVITIES_SUB_SECTION_HEADING
} from "../../constants.js";

import SectionHeading from "../../components/ui/SectionHeading";

const joinPack = <>
  <ul>
    <li>
      <b>•	Organized Rallies: </b>
      Explore scenic routes, conquer new roads, and ride with a crew. (All skill levels welcome!)
    </li>
    <li>
      <b>•	Adventure Rides: </b>
      Push your limits and discover hidden gems on exciting off-beat journeys.
    </li>
  </ul>
</>

const moreThanMiles = <>
  <ul>
    <li>
      We proudly support the "School by Cycle" initiative.
    </li>
    <li>
      Enjoy an exclusive 5% discount on all our premium e-bikes.
    </li>
    <li>
     Promote sustainable transportation while saving on your purchase.
    </li>
  </ul>
</>

const theLoop = <>
  <ul>
    <li>
       Contribute to sustainability and healthy living within your organization.
    </li>
    <li>
      Receive an additional 5% discount on our e-bikes.
    </li>
    <li>
      Encourage eco-friendly commuting and a healthier lifestyle among employees.
    </li>
  </ul>
</>

const VIR_ADVENTURE_DATA = [
  {
    id: 1,
    title: "Join the Pack:",
    description: joinPack,
  },
  {
    id: 2,
    title: "For Students:",
    description: moreThanMiles,
  },
  {
    id: 3,
    title: "For Corporates:",
    description: theLoop,
  },
]

const Activities = () => {
  return (
    <section id="activities-section">
      <div className="container">
        <SectionHeading 
          heading={ACTIVITIES_SECTION_HEADING} 
          headingClass={''} 
          subHeading={ACTIVITIES_SUB_SECTION_HEADING} 
          subHeadingClass={'activities-heading'} />
        <div>
          <h3>Your crew for epic rides and unforgettable adventures.</h3>
        </div>
        <div className="container-fluid activities d-flex justify-content-evenly align-items-center">
          {/* <figure className="activities-img">
            <img className="img-first" src="/images/activities/bike-hill.jpg" alt="" />
          </figure>
          <figure className="activities-img ">
            <img className="img-middle" src="/images/activities/bike-rider.jpg" alt="" />
          </figure> */}
          <figure className="activities-img">
            <img src="/images/activities/adventure_community.jpg" alt="" />
          </figure>
        </div>
        <div className="activities-content mt-5">
          {VIR_ADVENTURE_DATA.map(item => {
            return (
              <div key={item.id+'ws'} className="about-vir-card-content mt-5">
                <h3>{item.title}</h3>
                <p className="text-dark-gray fs-4">{item.description}</p>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Activities;