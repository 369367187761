import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import {
  BOOK_NOW
} from "../../constants.js";

const ProductCard = ({product}) => {
  const [imageURL, setImageURL] = useState(product.productImages[0]);
  const navigate = useNavigate();

  const selectProductVariation = (e, type) => {
    e.preventDefault();
    setImageURL(type.image[0]);
  }
  
  const bookNow = () => {
    navigate(`/product-details/${product._id}`);
  }

  return (
    <>
      <div className="prod-card">
        <div class="prod-heading">
            <h2>{product.productName}</h2>
            <p class="text-light-gray">{product.tagline}</p>
        </div>
        <div 
          className="prod-section-img"
          // style={{
          //   width: '100%',
          //   height: '600px',
          //   backgroundImage: `url(${imageURL})`,
          //   backgroundRepeat: 'no-repeat',
          //   backgroundSize: 'contain',
          //   backgroundPosition: 'center',
          // }}
          >
          <figure>
            <img src={imageURL} className="prod-img img-fluid" alt={product.productName} />
          </figure>
        </div>
        <div className="prod-info">
          <div className="color-option d-flex justify-content-end">
              {
                product.colorOptions.map(type => (
                  <button 
                    style={{backgroundColor: `${type.colorCode}`}}
                    className="round-btn"
                    title={type.color}
                    key={type.colorCode}
                    onClick={(e) => selectProductVariation(e, type)}
                    ></button>
                ))
              }
          </div>
          <div className="d-flex flex-wrap justify-content-between align-items-center mt-4">
            <h4 className="mb-0 fw-semibold">
              {product.productName}
            </h4>
            <h4 className="mb-0 fw-semibold">
              ₹{product.pricing.originalPrice} <span className="fs-5 fw-normal"><s>₹{product.pricing.slashPrice}</s></span>
            </h4>
          </div>
          <div className="prod-booking mt-4">
            <Button className={'w-100 product-booking-btn'} onClick={() => bookNow()}>
              {BOOK_NOW} &#8594;
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductCard;