import React from "react";
import Button from "../../components/ui/Button.js";
import SectionHeading from "../../components/ui/SectionHeading.js";
import YouTubeVideo from './YouTubeVideo';

import {
  ABOUT_HEADING,
  ABOUT_SUB_HEADING,
  ABOUT_SECTION_DESCRIPTION,
  READ_MORE
} from "../../constants.js";

const AboutSection = () => {

  return (
    <section id="about-section" className="section pb-0" style={{'minHeight': 0}}>
      <div className="about-container-wrapper">
        <div className="about-container d-flex flex-column justify-content-around align-items-center align-items-xl-start">
          <div className="about-container-heading">
            <SectionHeading 
              heading={ABOUT_HEADING} 
              headingClass={''} 
              subHeading={ABOUT_SUB_HEADING} 
              subHeadingClass={'about-sub-heading'} />
          </div>
          <div className="about-container-text d-flex">
            <div className="content about-container-text-content">
              {ABOUT_SECTION_DESCRIPTION}
            </div>
            {/* <div className="about-container-links">
              <Button className={'about-btn'} onClick={() => {}}>
                {READ_MORE}
              </Button>
            </div> */}
          </div>
        </div>
        <figure>
          <img src="/images/about_home.png" className="img-fluid w-100" alt="About VIR Bike" />
        </figure>
        {/* <div className="youtube-container">
          <YouTubeVideo />
        </div> */}
      </div>
    </section>
  )
}

export default AboutSection;