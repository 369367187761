const HeroCommunity = () => {
  return (
    <div id="heroCommunity" className="d-flex justify-content-center heroCommunity">
      <figure className="img-container">
        <img className="img-fluid w-100" src="/images/community-group.jpg" alt="" />
      </figure>
      <div className="heroCommunity-caption">
        <h2>Welcome to the VIR Community</h2>
        <h3>Join us in celebrating the spirit of adventure, sustainability, and community.</h3>
      </div>
      <div className="heroCommunity-card d-none">
        <div className="heroCommunity-card-content">
          <h4>Morbi blandit enim</h4>
          <p>
            Embrace the thrill of the open road, the hum of wheels on pavement, and the camaraderie of fellow cyclists. Our VIR Community is a hub for passionate riders who share a love for all things cycling. Whether you're a seasoned pro, a weekend warrior, or just getting started, this is the place to connect, and pedal together.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HeroCommunity;