const ContactDetails = () => {
  return (
    <div className="d-flex flex-column">
      <div className="contact-details">
        <h2>Call Us:</h2>
        <a href="tel:+91-9766396579">+91-9766396579</a>
      </div>
      <div className="contact-details">
        <h2>Send Mail:</h2>
        <a href="mailto:pedal@virbike.com">pedal@virbike.com</a>
      </div>
      <div className="contact-details">
        <h2>Our Location:</h2>
        <p>
          679/2/2, Kuruli<br/>
          Chakan, Alandi Road,<br/>
          Pune-410501, Maharashtra, India
        </p>
      </div>
    </div>
  )
}

export default ContactDetails