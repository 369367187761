import React, { useState } from 'react';
import {
  FAQ_SECTION_HEADING,
  FAQ_SECTION_SUB_HEADING
} from "../../constants.js";
import SectionHeading from './SectionHeading.js';

const FAQ_DATA = [
  {
    id: 1,
    question : 'What is an e-bike?',
    answer: 'An e-bike is an electric bike that uses a motor and battery to give the rider assistance when pedaling. It is a great way to get around without having to exert too much energy.'
  },
  {
    id: 2,
    question : 'What are the available payment option?',
    answer: 'Virbike accepts multiple payment methods like UPI, Wallets, Credit Cards, Debit Cards, Net Banking, etc. We also offer EMI option.'
  },
  {
    id: 3,
    question : 'What are the different modes in VIR BIKE?',
    answer: 'Throttle Mode - Twist and go at 25km/hr, 100% electric ride. PAS Mode - Pedal with up to 90% electric ride.'
  },
  {
    id: 4,
    question : 'Does an Electric bicycle work without batteries?',
    answer: 'Yes, you can ride a VirBike even without using batteries just like any other normal bicycle.'
  }
]

const FAQ = () => {
  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleItem = (index) => {
    setOpenIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((item) => item !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };

  return (
    <section id="faq-section" className='section' style={{paddingTop: '0'}}>
      <div className="faq-content-wrapper d-flex flex-column justify-content-center align-items-center align-self-center">
        <SectionHeading
          heading={FAQ_SECTION_HEADING} 
          headingClass={''} 
          subHeading={FAQ_SECTION_SUB_HEADING} 
          subHeadingClass={''} />
          <div className="faq-content">
            {
              FAQ_DATA.map((item, index) => (
                <div className="faq-content-block" key={index}>
                  <div className="faq-content-block_question text-dark-gray">
                    {item.question}
                  </div>
                  <div className="faq-content-block_answer text-light-gray">
                    {item.answer}
                  </div>
                </div>
              ))
            }
          </div>
      </div>
    </section>
  );
}

export default FAQ;
